import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Banner from 'components/ui/Banner';

import { SectionTitle } from 'helpers/definitions';
import FormatHtml from 'components/utils/FormatHtml';

interface SectionHeroBanner extends SectionTitle {
  content: string;
  linkTo: string;
  linkText: string;
}

interface HeroBanner {
  html: React.ReactNode;
  frontmatter: SectionHeroBanner;
};

interface Props {
  hideProfileButton: boolean;
}
const HeroBanner: React.FC<Props> = ({hideProfileButton = false}) => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "hero section" } }) {
        html
        frontmatter {
          title
          subtitle
          content
          linkTo
          linkText
        }
      }
    }
  `);
  const {html, frontmatter}: HeroBanner = markdownRemark;

  return (
    <Banner
      title={frontmatter.title}
      subtitle={frontmatter.subtitle}
      content={<FormatHtml content={html} />}
      linkTo={frontmatter.linkTo}
      linkText={frontmatter.linkText}
      hideLink={hideProfileButton}
    />
  );
};

export default HeroBanner;
